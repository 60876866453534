import 'url-polyfill';
import React, { Component } from 'react';

import { Button, Card, Form4, Input, Select, Message, Modal } from '../components/base/index';
import AuthService from '../components/AuthService';
import { changeLanguage, getText, DefaultLang } from '../i18n/i18next';
import withTracker from '../components/withTracker';
import Ajax from '../components/Ajax';
import { SetTitle, GUID } from '../components/Common';
import { ReactComponent as CompanyLogo } from '../img/mr_spedag_logo.svg';
import userIcon from '../img/signup_title.png';
import passwordIcon from '../img/password_lock.png';
import { isTimeout, setIsTimeout, hasProfile } from '../components/withAuth';
import { updateServiceWorker } from '../registerServiceWorker';
import { isArray } from 'util';
import './Login.scss';

const FormItem4 = Form4.Item;
const Option = Select.Option;
const userMenuApiUrl = `${process.env.PUBLIC_URL}/api/v1/Users/UserModuleList`;

class NormalLoginForm extends Component {
    state = {
        profiles: [],
        modalTermsOfUseVisible: false,
        needChangePassword: false
    }

    formRef = React.createRef();

    componentDidMount() {
        SetTitle('Login');
        this.Auth = new AuthService();
        changeLanguage(localStorage.getItem('default_lang') || DefaultLang);
        let loggedIn = this.Auth.loggedIn();
        if (loggedIn && !isTimeout()) {
            setIsTimeout(false);
            this.selectUserProfile();
        }

        let token = this.Auth.getToken();
        if (isTimeout() && token) {
            setIsTimeout(false);
            this.Auth.logout();
            Message.error({
                content: getText('msgLoginTimeout'),
                duration: 0,
                onClick: () => Message.destroy()
            });
        }
        else if (loggedIn && !this.Auth.getProfile().profile) {
            this.Auth.logout();
            Message.error({
                content: getText('msgProfileNotFound'),
                duration: 0,
                onClick: () => Message.destroy()
            });
        }
    }

    getDefaultDashboardUrl = async function () {
        const menu = await Ajax.get(userMenuApiUrl);
        const dashboard = menu.data.filter(m => m.section && m.section !== 'setting' && m.section !== 'admin');
        if (dashboard.length > 0) {
            let profile = this.Auth.getProfile().profile;
            if (profile === 'AGENT' || profile === 'CONSIGNEE') {
                let resDB = dashboard.filter(d => d.key === 'm_booking_approval_enhance');
                if (resDB.length > 0) {
                    return resDB[0].url;
                }
            }
            return dashboard[0].url;
        }
        return `${process.env.PUBLIC_URL}/`;
    }

    redirectToSystem = async function() {
        const { history, location } = this.props;
        const { needChangePassword } = this.state;

        if (needChangePassword) {
            history.replace(`${process.env.PUBLIC_URL}/BMS/Setting/update_user_password`);

            return;
        }

        let searchParams = new URLSearchParams(location.search);
        let defaultUrl = await this.getDefaultDashboardUrl();
        let toUrl = searchParams.get("redir") || defaultUrl;
        history.replace(toUrl);
    }

    selectUserProfile = () => {
        //ming sing 20210129: this.Auth.selectUserProfile(profile) function already perform the user preference function
        //this.Auth.needRenewPreferences();
        //if (this.Auth.getUserPreferences().length > 0) {
        //    this.setState({
        //        preferences: this.Auth.getUserPreferences()
        //    });
        //}
        Ajax.get(`${process.env.PUBLIC_URL}/api/v1/admin/getprofileTypeOpts`)
            .then(res => {
                let userProfiles = this.Auth.getUserProfiles(), profiles = [], profileOpts = [];
                if (res && isArray(res.data) && res.data.length > 0) {
                    profileOpts = res.data;
                    this.Auth.setProfileList(profileOpts);
                }

                if (this.Auth.getProfile().profile) {
                    this.redirectToSystem();
                }
                else if (userProfiles.length > 0) {
                    if (profileOpts.length > 0) {
                        for (let i = 0; i < userProfiles.length; ++i) {
                            for (let j = 0; j < profileOpts.length; ++j) {
                                if (userProfiles[i] === profileOpts[j].profile_type_id) {
                                    profiles.push({ key: profileOpts[j].profile_type_id, value: profileOpts[j].profile_type });
                                }
                            }
                        }
                    }
                    else {
                        for (let i = 0; i < userProfiles.length; ++i) {
                            profiles.push({ key: userProfiles[i], value: userProfiles[i] });
                        }
                    }
                    this.setState({
                        profiles
                    }, () => {
                        const { current: { resetFields } } = this.formRef;
                        resetFields && resetFields();
                    });
                }
                else {
                    this.redirectToSystem();
                }
            })
            .catch(error => {
                let userProfiles = this.Auth.getUserProfiles(), profiles = [];

                if (this.Auth.getProfile().profile) {
                    this.redirectToSystem();
                }
                else if (userProfiles.length > 0) {
                    for (let i = 0; i < userProfiles.length; ++i) {
                        profiles.push({ key: userProfiles[i], value: userProfiles[i] });
                    }
                    this.setState({
                        profiles
                    });
                }
                else {
                    this.redirectToSystem();
                }
            });
    }

    onFinish = (values) => {
        const { userName, password, language, profile } = values;

        if (this.Auth.loggedIn()) {
            this.Auth.selectUserProfile(profile)
                .then(() => {
                    this.selectUserProfile();
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.Message) {
                        Message.error(err.response.data.Message, 30);
                    }
                    else if (err.response && err.response.data && err.response.data.msgKey) {
                        Message.error(getText(err.response.data.msgKey));
                    }
                    else if (err.response && err.response.status) {
                        if (err.response.status === 404) Message.error('404 Not Found');
                        else if (err.response.status === 500) Message.error('500 Internal Server Error');
                        else Message.error(`${err.response.status} Error`);
                    }
                    else {
                        console.log('Server not available', err)
                        Message.error('Server not available');
                    }
                });
        }
        else {
            this.Auth.login(userName, password, language)
                .then(res => {
                    updateServiceWorker();
                    this.setState({ needChangePassword: res.data ? res.data.needChangePassword : false }, () => {
                        this.selectUserProfile();
                    });
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.Message) {
                        Message.error(err.response.data.Message, 30);
                    }
                    else if (err.response && err.response.data && err.response.data.msgKey) {
                        Message.error(getText(err.response.data.msgKey));
                    }
                    else if (err.response && err.response.status) {
                        if (err.response.status === 404) Message.error('404 Not Found');
                        else if (err.response.status === 500) Message.error('500 Internal Server Error');
                        else Message.error(`${err.response.status} Error`);
                    }
                    else {
                        Message.error('Server not available');
                    }
                });
        }
    }

    //onFinishFailed = ({ values, errorFields, outOfDate }) => {
    //    console.log(values, errorFields, outOfDate)
    //}

    setModalTermsOfUseVisible = modalTermsOfUseVisible => this.setState({ modalTermsOfUseVisible });

    render() {
        const { profiles } = this.state;
        
        return (
            <div className="login-page">
                <Card bordered={false}>
                    <div className="formWrapper">
                        <Form4
                            onFinish={this.onFinish}
                            //onFinishFailed={this.onFinishFailed}
                            className="login-form"
                            initialValues={{
                                language: localStorage.getItem('default_lang') || "en-US",
                                profile: profiles.length > 0 ? profiles[0].key : ''
                            }}
                            ref={this.formRef}
                        >
                            {
                                profiles.length === 0 &&
                                <>
                                    <FormItem4
                                        name='userName'
                                        rules={[{
                                            required: true,
                                            message: 'Please input your username!'
                                        }]}
                                    >
                                        <Input prefix={<img src={userIcon} alt="Username" className="loginIcon" />} placeholder="Username" />
                                    </FormItem4>
                                    <FormItem4
                                        name='password'
                                        rules={[{
                                            required: true,
                                            message: 'Please input your Password!'
                                        }]}
                                    >
                                        <Input prefix={<img src={passwordIcon} alt="Password" className="loginIcon" />} type="password" placeholder="Password" />
                                    </FormItem4>
                                    <FormItem4
                                        name='language'
                                        rules={[{
                                            required: true,
                                            message: 'Please select your language!'
                                        }]}
                                    >
                                        <Select placeholder="Select a language" onChange={changeLanguage} >
                                            <Option key={GUID()} value="en-US">English</Option>
                                            <Option key={GUID()} value="zh-HK">中文(繁體)</Option>
                                            <Option key={GUID()} value="zh-CN">中文(简体)</Option>
                                        </Select>
                                    </FormItem4>
                                </>
                            }
                            {
                                profiles.length > 0 &&
                                <FormItem4
                                    label="Your profile"
                                    style={{ display: 'block' }}
                                    name='profile'
                                    rules={[{
                                        required: true,
                                        message: 'Please select your profile!'
                                    }]}
                                >
                                    <Select placeholder="Select a profile">
                                        {
                                            profiles.map((p, i) => <Option key={i} value={p.key}>{p.value}</Option>)
                                        }
                                    </Select>

                                </FormItem4>
                            }
                            <FormItem4>
                                <Button type="primary" htmlType="submit" className="login-form-button">
                                    {getText('btnLogin')}
                                </Button>
                                {/*<a className="login-form-register" href="Registration">{getText('lbRegister')}</a>*/}
                                <a className="login-form-forgot" href="ForgotPassword">{getText('lbForgotPW')}</a>
                                <Button className="login-form-register" style={{ padding: '0px', height: '22px', lineHeight: '0px' }} type="link" onClick={() => this.setModalTermsOfUseVisible(true)}>{getText('lbTermsOfUse')}</Button>
                            </FormItem4>
                        </Form4>
                    </div>
                    <div className="card-login-caption">
                        {/*
                        <h1>{getText('lbMR')}</h1>
                        <h2>M+R Core System</h2>
                        <img src={companyLogo} alt="Logo" className="companyLogo" />
                         */}
                        <CompanyLogo className="companyLogo" />
                    </div>
                </Card>

                <Modal
                    title={
                        <div className="terms-modal-header">
                            <h1>Terms of Use</h1>
                        </div>
                    }
                    visible={this.state.modalTermsOfUseVisible}
                    onCancel={() => this.setModalTermsOfUseVisible(false)}
                    footer={null}
                >
                    <div className="terms-modal-content">
                        <p>{getText("lbTermsOfUseContent")}</p>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTracker(NormalLoginForm);