import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row, Col, Statistic, Tooltip, Dropdown, Menu } from '../components/base';
import { RightOutlined, PushpinOutlined, UserSwitchOutlined } from '@ant-design/icons';
import NotificationBellButtonGroup from '../components/Notification/NotificationBellButtonGroup';
import { enableNotification } from '../components/Notification/common';
import SystemMenu from '../components/SystemMenu';
import LoggedInUser from '../components/LoggedInUser';
import BreadcrumbBar from '../components/Breadcrumb';
import routingTable from '../routingTable';
import AuthService from '../components/AuthService';
import { setIsTimeout, setResetTimer, setHasBLRenewToken } from '../components/withAuth';
import history from '../components/history';

import { ReactComponent as CompanyLogo } from '../img/mr_spedag_logo.svg';
import './BaseLayout.scss';

const { Header, Footer, Sider, Content } = Layout;
const Auth = new AuthService();
const Countdown = Statistic.Countdown;

const countDown = (timer, onFinish) => {
    return (<Tooltip title="This is a timeout countdown." overlayClassName="tooltipToRight" placement="topRight"><div><Countdown value={timer} format='mm:ss' placement="left" valueStyle={{ fontSize: "16px", }} onFinish={() => {
        onFinish && onFinish()
    }} /></div></Tooltip>)
}

export default class BaseLayout extends Component {
    state = {
        hideMenu: (localStorage.getItem('hideMenu') === 'true'),
        collapsed: (localStorage.getItem('collapsedMenu') === 'true'),
        autoHideCollapsed: (localStorage.getItem('hideMenu') === 'true'),
        shrinkHeader: false,
        isLightTheme: true,
        resetTimer: false,
        menuIconClass: '',
        profileMenu:[]
    }

    componentDidMount = () => {
        if (Auth.loggedIn() && !Auth.getProfile().profile && this.props.location.pathname.indexOf('/ChangeUser') <= -1) {
            this.redirectToLoginPage(false);
        }
        //window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('storage', this.handleStorage);
        window.addEventListener('scroll', this.listenToScroll);
        this.getUserProfile();
    }

    componentWillUnmount = () => {
        window.removeEventListener('storage', this.handleStorage);
        window.removeEventListener('scroll', this.listenToScroll);
    }

    handleStorage = (e) => {
        if (e.key === 'id_token') {
            if (e.newValue) {
                this.resetTimer();
            }
            else {
                this.redirectToLoginPage(false);
            }
        }
        else if (e.key === 'refreshSailing' && e.newValue && e.newValue.toString().toLowerCase() === 'true') {
            let updateBookingDate = localStorage.getItem('updateBookingDate');
            Auth.refreshSailing && Auth.refreshSailing(updateBookingDate);
            localStorage.setItem('refreshSailing', false);
            localStorage.setItem('updateBookingDate', false);
        }
        else if (e.key === 'refreshHBLSailing' && e.newValue && e.newValue.toString().toLowerCase() === 'true') {
            Auth.refreshHBLSailing && Auth.refreshHBLSailing();
            localStorage.setItem('refreshHBLSailing', false);
        }
        else if (e.key === 'cargosphere_access_token') {
            let cargosphere_access_token = localStorage.getItem('cargosphere_access_token');
            Auth.refreshCargosphereAccessTokenState && Auth.refreshCargosphereAccessTokenState(cargosphere_access_token);
            localStorage.setItem('refreshCargosphereAccessTokenState', false);
        }
    }

    redirectToLoginPage = (isTimeOut) => {
        const { location: { pathname, search, hash } } = history;

        let currUrl = `${pathname}${search}${hash}`;
        let loginUrl = `${process.env.PUBLIC_URL}/login`;

        if (isTimeOut) setIsTimeout(true);

        if (currUrl) loginUrl += `?redir=${encodeURIComponent(currUrl)}`;
        history.replace(loginUrl);
    }
    
    redirectToChangePassword = () => {
        const { location: { pathname } } = history;

        if (pathname !== '/BMS/Setting/update_user_password') {
            history.replace(`${process.env.PUBLIC_URL}/BMS/Setting/update_user_password`);
        }
    }

    onCountDownFinish = () => {
        let token = Auth.getToken();
        if (token && !Auth.isTokenExpired(token)) {
            Auth.renewIfNeeded(() => {
                this.resetTimer();
                setHasBLRenewToken(true);
            });
            return;
        }

        this.redirectToLoginPage(true);
    }

    toggleMenu = () => {
        this.setState((prevState) => {
            localStorage.setItem('hideMenu', !prevState.hideMenu);
            localStorage.setItem('collapsedMenu', false);
            return {
                hideMenu: !prevState.hideMenu,
                collapsed: false
            };
        }, () => {
                this.listenToScroll();
        });
    }

    onCollapse = (collapsed) => {
        localStorage.setItem('collapsedMenu', collapsed);
        this.setState({ collapsed });
    }

    onAutoHideCollapse = (autoHideCollapsed) => {
        this.setState({ autoHideCollapsed }, () => {
            this.listenToScroll();
        });
    }

    onChangeTheme = (isLightTheme) => {
        this.setState({
            isLightTheme
        });
    }

    resetTimer = () => {
        const { resetTimer } = this.state;
        this.setState({ resetTimer: !resetTimer });
    }

    componentDidUpdate() {
        if (Auth.loggedIn() && !Auth.getProfile().profile && this.props.location.pathname.indexOf('/ChangeUser') <= -1) {
            this.redirectToLoginPage(false);
        }
        if (Auth.getForceChangePassword()) {
            this.redirectToChangePassword();
        }
    }

    listenToScroll = (e) => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop
        const { menuIconClass, hideMenu, autoHideCollapsed } = this.state;

        if (winScroll > 64 && menuIconClass !== 'fixed') {
            this.setState({ menuIconClass: 'fixed' });
        }
        else if (winScroll <= 64 && menuIconClass === 'fixed') {
            this.setState({ menuIconClass: '' });
        }

        let searchTable = document.getElementsByClassName("resultTable");
        let expandBtn = document.getElementsByClassName("tableButtonPanel");

        let toTopBtn = document.getElementById('toTop');
        let toBottomBtn = document.getElementById('toBottom');

        if (searchTable && searchTable.length > 0) {
            if ((searchTable[0].getBoundingClientRect() ? searchTable[0].getBoundingClientRect().top : 0) < 0 ) {
                searchTable[0].classList.add("fixTableHeader");

                if (hideMenu && autoHideCollapsed) searchTable[0].classList.add("hideMenu");
                else searchTable[0].classList.remove("hideMenu");
            }
            else {
                searchTable[0].classList.remove("fixTableHeader");
                searchTable[0].classList.remove("hideMenu");
            }
        }

        if (expandBtn && expandBtn.length > 0) {
            if ((searchTable[0].getBoundingClientRect() ? searchTable[0].getBoundingClientRect().top : 0) < 0) expandBtn[0].classList.add('fixTableHeader');
            else expandBtn[0].classList.remove('fixTableHeader');
        }

        if (toTopBtn) {
            toTopBtn.style.display = (winScroll > 200) ? "block" : "none";
        }

        if (toBottomBtn) {
            let scrollingElement = (document.scrollingElement || document.body);
            toBottomBtn.style.display = ((scrollingElement.scrollHeight - scrollingElement.scrollTop) >= document.body.offsetHeight + 200) ? "block" : "none";
        }
    }

    render() {
        const { location, history, children } = this.props;
        const { hideMenu, collapsed, autoHideCollapsed, shrinkHeader, isLightTheme, menuIconClass } = this.state;
        const now = new Date();
        const contentOnly = location.pathname === "/Login";
        const headerStyle = {
            display: contentOnly ? 'none' : null
        };
        const menuWidth = contentOnly ? 0 : (hideMenu && autoHideCollapsed) ? 5 : 250;
        const menuClass = contentOnly ? 'contentOnly' : (hideMenu && autoHideCollapsed) ? 'hidden' : '';
        const rootClassName = `root ${shrinkHeader ? 'shrinkHeader' : ''} ${isLightTheme ? '' : 'darkTheme'}`;
        const menuIconType = hideMenu && autoHideCollapsed ? "right" : "pushpin";

        const timer = Auth.getTokenExpireTime();
        setResetTimer(this.resetTimer);
        setHasBLRenewToken(false);

        Auth.renewIfNeeded(() => {
            this.resetTimer();
            setHasBLRenewToken(true);
        });
        const { profileMenu } = this.state;
        return (
            <Layout className={rootClassName}>
                <Header className="header" style={headerStyle}>
                    <CompanyLogo className="companyLogo" />
                </Header>
                <Layout className="main">
                    <Sider
                        width={menuWidth}
                        theme="light"
                        collapsible
                        collapsed={collapsed /*|| autoHideCollapsed*/}
                        onCollapse={this.onCollapse}
                        className={`leftSider ${menuClass}`}
                        trigger={null}
                        onMouseEnter={() => { this.onAutoHideCollapse(false); }}
                        onMouseLeave={() => { this.onAutoHideCollapse(true); }}
                    >
                        <SystemMenu history={history} location={location} />
                    </Sider>
                    {
                        menuIconType === "right" &&
                        <RightOutlined
                            className={`menuToggle ${menuClass} ${menuIconClass}`}
                            onClick={this.toggleMenu}
                            rotate={hideMenu ? 0 : 45}
                            onMouseEnter={() => { this.onAutoHideCollapse(false); }}
                            onMouseLeave={() => { this.onAutoHideCollapse(true); }}
                        />

                    }
                    {
                        menuIconType === "pushpin" &&
                        <PushpinOutlined
                            className={`menuToggle ${menuClass} ${menuIconClass}`}
                            onClick={this.toggleMenu}
                            rotate={hideMenu ? 0 : 45}
                            onMouseEnter={() => { this.onAutoHideCollapse(false); }}
                            onMouseLeave={() => { this.onAutoHideCollapse(true); }}
                        />
                    }
                    {
                        /*<DynamicIcon
                        className={`menuToggle ${menuClass} ${menuIconClass}`}
                        type={menuIconType}
                        theme="outlined"
                        onClick={this.toggleMenu}
                        rotate={hideMenu ? 0 : 45}
                        onMouseEnter={() => { this.onAutoHideCollapse(false); }}
                        onMouseLeave={() => { this.onAutoHideCollapse(true); }}
                    />*/
                    }
                    <Layout>
                        <Content className="mainContent">
                            <Row gutter={16}>
                                <Col xs={24} md={14}>
                                    <BreadcrumbBar routes={routingTable} location={location} />
                                </Col>
                                <Col xs={24} md={10} className="loginedUser">
                                    <Row type="flex" style={{float: "right", flexFlow: "nowrap"}}>
                                        <Col>
                                            <Link to='/BMS/Setting/user_profile' style={{ float: "left" }}><LoggedInUser /></Link>
                                            <Dropdown overlay={profileMenu}
                                            >
                                                <UserSwitchOutlined />
                                            </Dropdown>
                                            {countDown(timer, this.onCountDownFinish)}
                                        </Col>
                                        {
                                        enableNotification &&
                                        <Col style={{ display: "flex", alignItems: "center", width: '56px'}}>
                                            <NotificationBellButtonGroup />
                                        </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                            {children}
                        </Content>
                        <Footer className="footer"><a href="https://www.mrspedag.com/">M+R SPEDAG GROUP</a> &copy; {now.getFullYear()}</Footer>
                    </Layout>
                </Layout>
            </Layout>
        );
    }

    getUserProfile = () => {
        let userProfiles = Auth.getUserProfiles();
        let profileOpts = [];
        let profileList = Auth.getProfileList();
        for (let i = 0; i < userProfiles.length; ++i) {
            for (let j = 0; j < profileList.length; ++j) {
                if (userProfiles[i] === profileList[j].profile_type_id) {
                    profileOpts.push({
                        key: profileList[j].profile_type_id,
                        value: profileList[j].profile_type
                    });
                    break;
                }
            }
        }

        let profileMenu = (
            <Menu onClick={this.handleMenuItemClick}>
                {profileOpts.map(item => (
                    <Menu.Item key={item.key}>{item.value}</Menu.Item>
                ))}
            </Menu>
        );
        this.setState({ profileMenu });
    }

    handleMenuItemClick = ({ key }) => {
        if (Auth.getProfile().profile != key) {
            Auth.selectUserProfile(key)
                .then(() => {
                    location.reload();
                    location.href = '/'; 
                })
               .catch(err => {
                    if (err.response && err.response.data && err.response.data.Message) {
                        Message.error(err.response.data.Message, 30);
                    }
                    else if (err.response && err.response.data && err.response.data.msgKey) {
                        Message.error(getText(err.response.data.msgKey));
                    }
                    else if (err.response && err.response.status) {
                        if (err.response.status === 404) Message.error('404 Not Found');
                        else if (err.response.status === 500) Message.error('500 Internal Server Error');
                        else Message.error(`${err.response.status} Error`);
                    }
                    else {
                        console.log('Server not available', err)
                        Message.error('Server not available');
                    }
               });
        }
    };
}
